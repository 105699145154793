import React from "react"
import { Box, Text } from "theme-ui"

export default function Quote({ quote, cite }) {
  return (
    <Box as="blockquote" sx={{ pl: 4, my: 5, borderLeft: '4px solid' }}>
      <Text as="p" variant="title02" sx={{ fontFamily: "display", m: 0 }}>{quote}</Text>
      {cite && <Box as="footer">
        <Text variant="label" sx={{ mt: 3 }}>— {cite}</Text>
      </Box>}
    </Box>
  )
}