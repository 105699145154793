import React from "react"
import { graphql } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Grid, Box, Text, Image, Divider } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import Blockquote from "../components/Blockquote"

export const query = graphql`
  query {
    contentfulFooterPage(slug: {eq: "errata"}) {
      title
      slug
      body {
        json
      }
    }
  }
`

const ErrataPage = props => { 
  return (
    <Layout>
      <SEO title={props.data.contentfulFooterPage.title} />

      <Breadcrumbs title={props.data.contentfulFooterPage.title} />

      <Grid variant="textWrap">
        <Box>
          {documentToReactComponents(props.data.contentfulFooterPage.body.json, {
            renderMark: {
              [MARKS.BOLD]: text => <Text as="span" variant="label">{text}</Text>,
            },
            renderNode: {
              [BLOCKS.HEADING_1]: (node, children) => <Text as="h1" variant="title01">{children}</Text>,
              [BLOCKS.HEADING_2]: (node, children) => <Text as="h2" variant="title02" sx={{ mt: 4}}>{children}</Text>,
              [BLOCKS.HEADING_3]: (node, children) => <Text as="h3" variant="title03" sx={{ mt: 4}}>{children}</Text>,
              [BLOCKS.HEADING_4]: (node, children) => <Text as="h4" variant="label" sx={{ mt: 3}}>{children}</Text>,
              [BLOCKS.PARAGRAPH]: (node, children) => <Text as="p" variant="body">{children}</Text>,
              [BLOCKS.HR]: (node, children) => <Divider />,
              [BLOCKS.QUOTE]: (node, children) => <Blockquote quote={children} />,
              [INLINES.HYPERLINK]: (node, children) => {
                return (
                  <Text as="a" variant="link" href={node.data.uri}>{children}</Text>
                )
              },
              "embedded-asset-block": node => {
                const alt = node.data.target.fields.title["en-US"]
                const url = node.data.target.fields.file["en-US"].url
                return <Image alt={alt} src={url} sx={{ width: "100%", my: 5 }} />
              },
            },
          })}
        </Box>
      </Grid>
    </Layout>
  )
}

export default ErrataPage